import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from '../components/seo/seoall';
import adPlaceholder1 from "../images/placeholder/home-ad-1-placeholder.png";
import adPlaceholder2 from "../images/placeholder/home-ad-2-placeholder.png";


const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      allBlogDataJson(sort: {order: DESC, fields: date}) {
        edges {
          node {
            slug
            H1 
            excerpt
            IMAGE_URL
          }
        }
      }

        allAdDataJson {
            edges {
                node {
                    link
                    image
                    type
                    image_alt
                }
            }
        }
      
        allDescriptionsJson(filter: {page_location: {eq: "homepage"}}) {
          nodes {
            page_location
            description
              }
            }
          
    }
  `);

  const [blogData, setBlogData] = React.useState(data.allBlogDataJson.edges);
  const [adData, setAdData] = React.useState(data.allAdDataJson.edges);

  if (data.errors) {
    console.log("Error retrieving data", data.errors);
    return;
  }



  return (
    <div>




      <Layout classes='grey-bg'>
      <Seo description={data.allDescriptionsJson.nodes[0].description} slug='' category={data.allDescriptionsJson.nodes[0].page_location} />

        <div className="container-fluid p-0">
          <div className="row single-post m-auto justify-content-between mt-5">

            <div className="col-12 col-md-6 col-lg-4">
              <div className="card-body my-3 bg-white">
                <Link to={`/${blogData[0].node.slug}`} className='linkcard'>
                  <h2 className="card-title">{blogData[0].node.H1}</h2>
                  <p className="card-text">{blogData[0].node.excerpt.substring(0,100)}...</p>

                  <div  className="card-link">Read More →</div>
                </Link>
              </div>

              <div className="card-body my-3 bg-white">
                <Link to={`/${blogData[1].node.slug}`} className='linkcard'>
                  <h2 className="card-title">{blogData[1].node.H1}</h2>
                  <p className="card-text">{blogData[1].node.excerpt.substring(0,100)}...</p>

                  <div  className="card-link">Read More →</div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">

              <div className="card border-radius-0 mb-3 my-md-3 height-full border-0">
                <img className="card-img-top" src={blogData[2].node.IMAGE_URL} alt={blogData[2].node.H1} />
                <div className="card-body bg-white">
                  <Link to={`/${blogData[2].node.slug}`} className='linkcard'>
                    <h2 className="card-title">{blogData[2].node.H1}</h2>
                    <p className="card-text">{blogData[2].node.excerpt.substring(0,100)}...</p>

                    <div  className="card-link">Read More →</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-12">
                  <div className="card-body mb-3 mt-md-0 mt-lg-3 bg-white">
                    <Link to={`/${blogData[3].node.slug}`} className='linkcard'>
                      <h2 className="card-title">{blogData[3].node.H1}</h2>
                      <p className="card-text">{blogData[3].node.excerpt.substring(0,100)}...</p>

                      <div  className="card-link">Read More →</div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-12">
                  <div className="card-body mb-3 bg-white">
                    <Link to={`/${blogData[4].node.slug}`} className='linkcard'>
                      <h2 className="card-title">{blogData[4].node.H1}</h2>
                      <p className="card-text">{blogData[4].node.excerpt.substring(0,100)}...</p>

                      <div  className="card-link">Read More →</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 p-0 card-holder" key="added"></div>

          </div>
          <div className="row single-post m-auto justify-content-between mb-5">
            <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-0">
              <div className="card border-radius-0 h-100 border-0">
                <img className="card-img-top h-100 min-188" src={blogData[5].node.IMAGE_URL} alt={blogData[5].node.H1} />
                <div className="card-body bg-white">
                  <Link to={`/${blogData[5].node.slug}`} className='linkcard'>
                    <h2 className="card-title">{blogData[5].node.H1}</h2>
                    <p className="card-text">{blogData[5].node.excerpt.substring(0,100)}...</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card border-radius-0 h-100 border-0">
                <img className="card-img-top h-100 min-188" src={blogData[6].node.IMAGE_URL} alt={blogData[6].node.H1} />
                <div className="card-body bg-white">
                  <Link to={`/${blogData[6].node.slug}`} className='linkcard'>
                    <h2 className="card-title">{blogData[6].node.H1}</h2>
                    <p className="card-text">{blogData[6].node.excerpt.substring(0,100)}...</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mx-md-auto d-none d-lg-block">
              <div className='home-ad-wrapper mt-3 mt-lg-0'>
                {
                  adData.filter((el) => {
                    return el.node.type === "homepage_top";
                  }).map((el, i) => {
                    return(
                      <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                        <img src={el.node.image === ""? adPlaceholder1: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                      </a>
                    )
                  })
                }

              </div>
            </div>
          </div>

          <div className="row single-post m-auto justify-content-between pt-4 mb-5">
            <div className="col-12 col-md-12 col-lg-4 mb-5 mb-md-5 mb-lg-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-6 col-lg-12">
                  <div className='home-ad-wrapper mb-3'>
                    {
                      adData.filter((el) => {
                        return el.node.type === "homepage_bottom_1";
                      }).map((el, i) => {
                        return(
                          <a href={el.node.link} target='_blank' className='ad-link' key={`adl-1-${i}`}>
                            <img src={el.node.image === ""? adPlaceholder2: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                          </a>
                        )
                      })
                    }
                  </div>
                </div>

                {
                  blogData.slice(14, 18).map((el, i) => {
                    return(
                      <div className="col-12 col-md-6 col-lg-12" key={`nic-${i}`}>
                        <div className="card-body mb-3 bg-white">
                          <Link to={`/${el.node.slug}`} className='linkcard'>
                            <h2 className="card-title">{el.node.H1}</h2>
                            <p className="card-text">{el.node.excerpt.substring(0,100)}...</p>

                            <div  className="card-link">Read More →</div>
                          </Link>
                        </div>
                      </div>
                    )
                  })
                }

                <div className="col-12 col-md-6 col-lg-12">
                  <div className='home-ad-wrapper'>
                    {
                      adData.filter((el) => {
                        return el.node.type === "homepage_bottom_2";
                      }).map((el, i) => {
                        return(
                          <a href={el.node.link} target='_blank' className='ad-link' key={`adl-2-${i}`}>
                            <img src={el.node.image === ""? adPlaceholder2: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>


            </div>
            <div className="col-12 col-md-12 col-lg-8 long-container">

              {
                blogData.slice(7, 14).map((el, i) => {
                  return(
                    <div className="card-body mb-3 p-md-0" key={`ic-${i}`}>
                      <Link to={`/${el.node.slug}`} className='linkcard'>
                        <div className="d-flex align-items-center">
                          <div className="image-wrapper">
                            <img className="" src={el.node.IMAGE_URL} alt={el.node.H1} />
                          </div>
                          <div className="content-wrapper">
                            <h2 className="card-title">{el.node.H1}</h2>
                            <p className="card-text">{el.node.excerpt.substring(0,100)}...</p>

                            <div  className="card-link">Read More →</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default IndexPage;
